@use "sass:map";
@use "@angular/material" as mat;

@mixin hoteler-customer-list-theme($theme) {
  .customer {
    &-card {
      mat-card-header {
        mat-card-title {
          color: mat.get-theme-color($theme, on-surface);
        }
      }

      mat-card-content {
        .card-id,
        .phone {
          color: mat.get-theme-color($theme, on-surface);
        }
      }
    }
  }
}
