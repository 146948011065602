@use 'sass:map';
@use '@angular/material' as mat;

@mixin hoteler-error-code-theme($theme) {
  .hoteler-error {
    &-code,
    &-title,
    &-message {
      color: mat.get-theme-color($theme, on-surface);
    }
  }
}
