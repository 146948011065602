@use '@angular/material' as mat;

@mixin hoteler-page-header-theme($theme) {
  .primary-header {
    background: mat.get-theme-color($theme, surface);

    h1 {
      color: mat.get-theme-color($theme, on-surface);
    }
  }
}
