@use 'sass:map';
@use '@angular/material' as mat;

@mixin hoteler-apexcharts-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .apexcharts-title-text {
    color: mat.get-theme-color($theme, on-surface) !important;
    fill: mat.get-theme-color($theme, on-surface) !important;
  }

  .apexcharts-menu {
    background: mat.get-theme-color($theme, surface) !important;
  }

  .apexcharts-menu-item {
    &:hover {
      color: mat.get-theme-color($theme, on-surface) !important;
    }
  }

  .apexcharts-legend-text {
    // background: mat.get-color-from-palette($background, background) !important;
    color: mat.get-theme-color($theme, on-surface) !important;
  }
}