@use '@angular/material' as mat;

@mixin hoteler-navbar-theme($theme) {
  app-navbar {
    mat-toolbar {
      color: mat.get-theme-color($theme, primary) !important;
      background: mat.get-theme-color($theme, primary-container) !important;
    }

    a.hoteler-button {
      &:hover {
        background: {
          color: rgba(0, 0, 0, .4);
        };

        color: mat.get-theme-color($theme, tertiary);


      }
    }
  }

  .avatars {
    background-color: mat.get-theme-color($theme, primary);
  }
}
