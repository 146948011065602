@use "sass:map";
@use "@angular/material" as mat;

@mixin hoteler-room-theme($theme) {
  .room-list {
    color: mat.get-theme-color($theme, on-surface);
  }

  .room-card {
    mat-card {
      mat-card-header {
        mat-card-title {
          color: mat.get-theme-color($theme, on-surface);
        }
      }
    }
  }
}
