@use '@angular/material' as mat;
@use './app-theme';
@use './styles/breakpoints';
@use './styles/buttons';
@use './styles/colors';
@use './styles/general';
@use './styles/form';
@use './styles/table';
@use './styles/misc';
@use './styles/reboot';
@use './styles/view-transition';

// @use './styles/helpers';
@import './styles/tailwind.css';

$theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  )
));

// Include material core styles.
@include mat.elevation-classes;
@include mat.app-background;
@include mat.color-variants-backwards-compatibility($theme);
@include app-theme.hoteler-app-theme($theme);

:root {
  @include mat.all-component-themes($theme);
}
