@use "sass:math";

$gutter: 16px !default;

.hoteler {
  &-row {
    margin: {
      right: math.div(-$gutter, 2);
      left: math.div(-$gutter, 2);
    }
  }

  &-col {
    padding: {
      right: math.div($gutter, 2);
      left: math.div($gutter, 2);
    }
  }
}
