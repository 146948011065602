@use 'sass:map';
@use '@angular/material' as mat;

@mixin hoteler-banner-theme($theme) {
  app-banner {
    .banner {
      color: mat.get-theme-color($theme, on-surface);
      background: mat.get-theme-color($theme, surface);
    }
  }
}
