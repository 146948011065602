.form {
  &-inline {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &-field {
    margin: {
      right: 16px;
    }
  }
}

.form-field-full {
  .mat-mdc-form-field {
    width: 100%;
  }
}
