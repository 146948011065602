@use 'sass:map';
@use '@angular/material' as mat;

@mixin hoteler-register-theme($theme) {
  .register {
    &-card {
      mat-card-header,
      .visibility,
      .tip {
        color: mat.get-theme-color($theme, on-surface);
      }

      mat-card-content {
        form {
          mat-form-field {
            >mat-icon {
              color: mat.get-theme-color($theme, on-surface);
            }
          }
        }
      }
    }
  }
}
