@use 'sass:map';
@use '@angular/material' as mat;

@mixin hoteler-tag-theme($theme) {
    .tag {
        color: mat.get-theme-color($theme, on-surface);
        background: mat.get-theme-color($theme, surface);

        &-primary {
            background-color: mat.get-theme-color($theme, primary-container);
            color: mat.get-theme-color($theme, primary);
        }

        &-secondary {
            background-color: mat.get-theme-color($theme, secondary-container);
            color: mat.get-theme-color($theme, secondary);
        }

        &-tertiary {
            background-color: mat.get-theme-color($theme, tertiary-container);
            color: mat.get-theme-color($theme, tertiary);
        }

        &-error {
            background-color: mat.get-theme-color($theme, error-container);
            color: mat.get-theme-color($theme, error);
        }
    }
}
