@use 'sass:map';
@use '@angular/material' as mat;

@mixin hoteler-admin-layout-theme($theme) {
    .layout-container {
        .drawer {
            mat-icon {
                color: mat.get-theme-color($theme, on-surface);
            }

            .active {
                mat-icon {
                    color: mat.get-theme-color($theme, primary);
                }

                .mdc-list-item__primary-text {
                    color: mat.get-theme-color($theme, primary);
                }
            }
        }
    }
}
